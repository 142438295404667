<script>
/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkDialog from "@components/general/AkDialog";
import AkDropdown from "@components/input/AkDropdown";
import AkInputText from "@components/input/AkInputText.vue";
import AkInputTextArea from "@components/input/AkInputTextArea.vue";
import AkCheckbox from "@components/input/AkCheckbox.vue";
import Toast from "primevue/toast";
import AkYesNoButton from "@components/input/AkYesNoButton.vue";
import AkInputMask from "@components/input/AkInputMask.vue";
import AkInputNumber from "@components/input/AkInputNumber.vue";
import AkDropdownMultiple from "@components/input/AkDropdownMultiple.vue";
import AkDateTime from "@components/input/AkDateTime.vue";
import AkFileUpload from "@components/input/AkFileUpload.vue";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import utilsMixin from "@mixins/utilsMixin";
import imgMixin from "@mixins/imgMixin";
import urlMixin from "@mixins/urlMixin";
import dateFormatter from "@mixins/dateFormatter";
import roleMixin from "@mixins/roleMixin";
import precisionTypeInterventionMixin from "@mixins/precisionTypeInterventionMixin";

/* SERVICES */
import interventionService from "@services/interventionService";
import axeInterventionService from "@services/axeInterventionService";
import vehiculeImpliqueService from "@services/vehiculeImpliqueService";
import ddpService from "@services/ddpService";
import fileService from "@services/fileService";
import ceiService from "@services/ceiService";
import userService from "@services/userService";

/* CONSTANTES */
import sensConst from "@mixins/const/sensConst";
import origineAppelConst from "@mixins/const/origineAppelConst";
import typeInterventionConst from "@mixins/const/typeInterventionConst";
import moyensHumainsConst from "@mixins/const/moyensHumainsConst";
import moyensMaterielsConst from "@mixins/const/moyensMaterielsConst";
import etatChausseeConst from "@mixins/const/etatChausseeConst";
import conditionMeteoConst from "@mixins/const/conditionMeteoConst";
import voieConst from "@mixins/const/voieConst";
import genreVehiculeConst from "@mixins/const/genreVehiculeConst";
import natureDegatsConst from "@mixins/const/natureDegatsConst";
import intervenantConst from "@mixins/const/intervenantConst";
import actionConst from "@mixins/const/actionConst";
import vehiculeImpliqueConst from "@mixins/const/vehiculeImpliqueConst";
import affectationService from "@services/affectationService";
import rolePermissionService from "@services/rolePermissionService";

export default {
  components: {
    AkCheckbox,
    AkDateTime,
    AkDialog,
    AkDropdown,
    AkDropdownMultiple,
    AkFileUpload,
    AkFormView,
    AkInputMask,
    AkInputNumber,
    AkInputText,
    AkInputTextArea,
    AkYesNoButton,
    Toast,
  },
  mixins: [
    actionConst,
    conditionMeteoConst,
    dateFormatter,
    etatChausseeConst,
    genreVehiculeConst,
    imgMixin,
    intervenantConst,
    moyensHumainsConst,
    moyensMaterielsConst,
    natureDegatsConst,
    origineAppelConst,
    precisionTypeInterventionMixin,
    randomRef,
    roleMixin,
    sensConst,
    typeInterventionConst,
    urlMixin,
    utilsMixin,
    vehiculeImpliqueConst,
    voieConst,
  ],
  metaInfo() {
    return {
      title: "Édition d'une intervention",
    }
  },
  data() {
    return {
      interventionId: undefined,
      loading: true,
      submitted: false,
      currentIntervention: {},
      axeInterventionList: [],
      axeInterventionAutorouteList: [],
      vehiculesImpliques: [],
      moyensHumainsUtilises: {},
      moyensMaterielsUtilises: {},
      vehiculesImpliquesDict: {},
      userCeis: [],
      ddp: [],
      currentVehiculeImplique: null,
      currentDdp: null,
      update: true,
      files: [],
      filesToShow: null,
      errors: {},
      currentCei: null,
      author: null,
      creation: false,
      ceiAgents: [],
      selectedAgents: [],
      selectedCeiId: null,
      usePRFormatValidator: true,
      prValidatorRegexpFormat: "^[0-9.]*$"
    }
  },
  computed: {
    usedPrValidationRegexp() {
      return this.usePRFormatValidator ? this.prValidatorRegexpFormat : null;
    },
    precision1() {
      return this.hasPrecision1(this.currentIntervention.typeIntervention);
    },
    precision2() {
      let precisionNiveau1 = this.currentIntervention.precisionNiveau1;
      let precisionNiveau2 = this.currentIntervention.precisionNiveau2;
      let precision2 = this.hasPrecision2(precisionNiveau1);

      if (precisionNiveau2 == null) return precision2;
      return precision2 && this.isPrecision2RelatedToTypeIntervention(this.currentIntervention.typeIntervention, precisionNiveau1, precisionNiveau2);
    },
    canUpdate() {
      if(!this.interventionId) return false;
      return this.currentIntervention.canUpdate;
    },
  },
  mounted() {
    let promises = []
    this.currentUser = this.$store.state.userInformation;

    if (this.$route.meta?.creation) {
      this.creation = true;

      this.currentIntervention = {
        userId: this.currentUser.userId,
        callDate: new Date(),
        axeInterventionId: null,
        localisation: null,
        callOrigin: null,
        actionsRealisees: "",
        presenceTiersResponsable: "",
        vehiculesImpliques: "",
        sensListTmp: [],
        actionsRealiseesList: [],
        intervenantsList: [],
      }

      this.filesToShow = [];
      let p1 = axeInterventionService.findAll();
      p1.then(data => {
        this.axeInterventionList = data;
        this.axeInterventionAutorouteList = data.filter(axe => axe.autoroute);
      });
      promises.push(p1);

      let p2 = affectationService.findAllByUserId(this.currentUser.userId).then(data => {
        this.userCeis = data.ceiList;
        this.currentIntervention.ceiId = (this.userCeis.length === 1) ? this.userCeis[0].id : null;
        this.currentIntervention.ceiLabel = (this.userCeis.length === 1) ? this.userCeis[0].label : null;
      });
      promises.push(p2);

    } else {
      this.interventionId = this.$route.params.id;

      let p1 = interventionService.findInterventionById(this.interventionId)
      promises.push(p1)
      p1.then(data => {
        this.currentIntervention = data;
        // Si au chargement de la donnée, le pr ne respecte pas la rege attendu, on désactive complètement la validation.
        if (this.currentIntervention.localisation) {
          if (!this.currentIntervention.localisation.match(this.prValidatorRegexpFormat)) {
            this.usePRFormatValidator = false;
          }
        }

        let p0 = interventionService.canUpdateIntervention(this.interventionId).then(res => {
          if (!res) {
            this.$router.back();
          }
        });
        Promise.all([p0]);
        this.canUpdate = true;

        let pagents = ceiService.getCeiAgents(this.currentIntervention.ceiId);
        pagents.then(data => {
          this.ceiAgents = data;
        });
        promises.push(pagents);

        let pselectedagents = interventionService.getInterventionAgentIds(this.interventionId);
        pselectedagents.then(data => {
          this.selectedAgents = data;
        });
        promises.push(pselectedagents);

        this.currentIntervention.callDate = new Date(this.currentIntervention.callDate);

        /*
        * Mise en forme des objets permettant de traiter les moyens humains et matériels
        * */
        this.moyensHumainsUtilises = {};
        this.currentIntervention.moyensHumains.split(',').forEach(moyen => {
          let moyenSplit = moyen.split(':');
          this.moyensHumainsUtilises[moyenSplit[0]] = moyenSplit[1];
        });

        this.moyensMaterielsUtilises = {};
        this.currentIntervention.moyensMateriels.split(',').forEach(moyen => {
          let moyenSplit = moyen.split(':');
          this.moyensMaterielsUtilises[moyenSplit[0]] = moyenSplit[1];
        });

        /*
        * Mise en forme des sens
        * */
        if (this.currentIntervention.sens === '') {
          this.currentIntervention.sensListTmp = [];
        } else {
          this.currentIntervention.sensListTmp = this.currentIntervention.sens.split(",");
        }

        /*
        * Mise en forme des intervenants
        * */
        this.currentIntervention.intervenantsList = this.currentIntervention.intervenants.split(',');

        /*
        * Mise en place des actions réalisées
        * */
        this.currentIntervention.actionsRealiseesList = this.currentIntervention.actionsRealisees.split(',');

        /*
        * Mise en forme des véhicules impliqués
        * */
        this.vehiculesImpliquesDict = {};
        this.currentIntervention.vehiculesImpliques.split(',').forEach(vehicule => {
          let vehiculeSplit = vehicule.split(':');
          this.vehiculesImpliquesDict[vehiculeSplit[0]] = vehiculeSplit[1];
        });

        let p2 = axeInterventionService.findAll();
        p2.then(data => {
          this.axeInterventionList = data;
          this.axeInterventionAutorouteList = data.filter(axe => axe.autoroute);
        });
        promises.push(p2);

        let p6 = ceiService.findById(this.currentIntervention.ceiId);
        p6.then(data => {
          this.currentCei = data;
        });
        promises.push(p6);

        let p8 = userService.findById(this.currentIntervention.userId);
        p8.then(data => {
          this.author = data;
          this.author.fullName = this.author.firstName + " " + this.author.lastName;
        });
        promises.push(p8);
      });

      let p3 = vehiculeImpliqueService.findVehiculesImpliquesByInterventionId(this.interventionId);
      p3.then(data => {
        this.vehiculesImpliques = data;
      });
      promises.push(p3);

      let p4 = ddpService.findDdpByInterventionId(this.interventionId);
      p4.then(data => {
        this.ddp = data;
        for (let d of this.ddp) {
          d.canUpdateUnite = this.update && d.natureDegats === "AUTRES";
        }
      });
      promises.push(p4);

      let p5 = fileService.findAllByInterventionId(this.interventionId);
      p5.then(data => {
        this.filesToShow = data;
      })
      promises.push(p5);
    }


    let p6 = rolePermissionService.authorizeAccessByPermission(this.permissionConst.intervention.edit);
    promises.push(p6);

    Promise.all(promises).then(() => {
      if (this.currentIntervention.ceiId !== null) {
        this.getAgentsForCei();
      }
      this.loading = false;
    });
  },
  methods: {
    isEmpty(str) {
      return (!str || 0 === str.length);
    },
    getErrors(intervention) {
      let errors = {}

      if (intervention.axeInterventionId === '' || intervention.axeInterventionId === null) {
        errors.axeInterventionId = 'L\'axe d\'intervention est obligatoire'
      }
      if (intervention.callDate === '' || intervention.callDate === null) {
        errors.callDate = 'La date de l\'appel est obligatoire'
      }
      if (intervention.callTime === '' || intervention.callTime === null) {
        errors.callTime = 'L\'heure de l\'appel est obligatoire'
      }
      if (intervention.callOrigin === '' || intervention.callOrigin === null) {
        errors.callOrigin = 'L\'origine de l\'appel est obligatoire'
      }
      if (intervention.localisation === '' || intervention.localisation === null) {
        errors.localisation = 'La localisation est obligatoire'
      }
      if (intervention.ceiId === '' || intervention.ceiId === null) {
        errors.ceiId = 'Le CEI est obligatoire'
      }
      if (this.isAxeInterventionBretelle(intervention.axeInterventionId)) {
        if (intervention.noBretelle === '' || intervention.noBretelle === null) {
          errors.noBretelle = 'Le numéro de bretelle est obligatoire'
        }
        if (intervention.autorouteId === '' || intervention.autorouteId === null) {
          errors.autorouteId = 'Le numéro d\'autoroute est obligatoire'
        }
      }
      return errors
    },
    saveDraftIntervention() {
      this.currentIntervention.validated = false;
      this.currentIntervention.dateValidated = null;
      this.currentIntervention.status = "DRAFT";
      this.saveIntervention();
    },
    checkInterventionTimeValidity(intervention) {
      intervention.heureFin = this.checkTimeValidity(intervention.heureFin);
      intervention.callTime = this.checkTimeValidity(intervention.callTime);
      intervention.heureArriveeSite = this.checkTimeValidity(intervention.heureArriveeSite);
      intervention.heureDepartSite = this.checkTimeValidity(intervention.heureDepartSite);
    },
    checkTimeValidity(time) {
      if (time === null || time === undefined || time === "") return "";
      let timeValues = time.split(":");
      let heures = parseInt(timeValues[0]);
      let minutes = parseInt(timeValues[1]);

      if (heures < 0 || heures > 23) {
        time = "00:" + minutes;
      } else if (minutes < 0 || minutes > 59) {
        time = heures + ":00";
      }
      return time.replace("NaN", "00");
    },
    
    saveIntervention() {
      if (this.verifyInterventionData()) return;
      if (!this.creation) this.openUpdateDialog()
      else this.openCreateDialog()
    },
    
    validateIntervention() {
      if (this.verifyInterventionData()) return;
      if (!this.creation) this.openUpdateAndValidateDialog()
      else this.openCreateAndValidateDialog()
    },
    
    verifyInterventionData() {
      this.getRef().resetMessages();

      let errors = this.getErrors(this.currentIntervention);
      if (Object.keys(errors).length > 0) {
        this.errors = errors;
        this.getRef().error(this.$t("required_fields_empty"));
        return true;
      } else {
        this.errors = {};
      }

      errors = interventionService.checkFieldsSize(this.currentIntervention);
      if (Object.keys(errors).length > 0) {
        this.errors = errors;
        this.getRef().error(this.$t("fields_size_error"));
        return true;
      } else {
        this.errors = {};
      }

      /*Vérification que les données des véhicules impliqués et ddp ne sont pas vides*/
      for (let i = 0; i < this.vehiculesImpliques.length; i++) {
        if (this.isEmpty(this.vehiculesImpliques[i].genre)) {
          this.getRef().error(this.$t("vehicules_impliques.genre_empty_error"));
          return true;
        }
        if (this.vehiculesImpliques[i].genre === "AUTRE_VEHICULE" && this.isEmpty(this.vehiculesImpliques[i].typeVehicule)) {
          this.getRef().error(this.$t("vehicules_impliques.type_vehicule_empty_error"));
          return true;
        }
      }

      for (let i = 0; i < this.ddp.length; i++) {
        if (this.isEmpty(this.ddp[i].natureDegats) || this.isEmpty(this.ddp[i].quantite) || this.isEmpty(this.ddp[i].unite)) {
          this.getRef().error(this.$t("ddp.empty_field_error"));
          return true;
        }
      }
      /*Vérification que les données des véhicules impliqués et ddp ne sont pas vides*/
      return false;
    },
    prepareInterventionData() {
      /*Mise en forme des moyens humains et matériels pour les stocker en base*/
      this.currentIntervention.moyensHumains = "";
      for (let moyenHumain of Object.keys(this.moyensHumainsUtilises)) {
        if (this.moyensHumainsUtilises[moyenHumain] != null && this.moyensHumainsUtilises[moyenHumain] !== '' && this.moyensHumainsUtilises[moyenHumain] >= 0) {
          this.currentIntervention.moyensHumains += moyenHumain + ":" + this.moyensHumainsUtilises[moyenHumain] + ",";
        }
      }

      this.currentIntervention.moyensMateriels = "";
      for (let moyenMateriel of Object.keys(this.moyensMaterielsUtilises)) {
        if (this.moyensMaterielsUtilises[moyenMateriel] != null && this.moyensMaterielsUtilises[moyenMateriel] !== '' && this.moyensMaterielsUtilises[moyenMateriel] >= 0) {
          this.currentIntervention.moyensMateriels += moyenMateriel + ":" + this.moyensMaterielsUtilises[moyenMateriel] + ",";
        }
      }
      /*Mise en forme des moyens humains et matériels pour les stocker en base*/

      this.currentIntervention.sens = this.currentIntervention.sensListTmp.join(',');

      /*Mise en forme des intervenants*/
      this.currentIntervention.intervenants = "";
      this.currentIntervention.intervenants = this.currentIntervention.intervenantsList.join(',');
      /*Mise en forme des intervenants*/

      /*Mise en forme des actions réalisées*/
      this.currentIntervention.actionsRealisees = "";
      this.currentIntervention.actionsRealisees = this.currentIntervention.actionsRealiseesList.join(',');
      /*Mise en forme des actions réalisées*/

      /*Mise en forme des véhicules impliqués*/
      this.currentIntervention.vehiculesImpliques = "";
      for (let vehicule of Object.keys(this.vehiculesImpliquesDict)) {
        if (this.vehiculesImpliquesDict[vehicule] != null && this.vehiculesImpliquesDict[vehicule] !== '' && this.vehiculesImpliquesDict[vehicule] >= 0) {
          this.currentIntervention.vehiculesImpliques += vehicule + ":" + this.vehiculesImpliquesDict[vehicule] + ",";
        }
      }
      /*Mise en forme des véhicules impliqués*/

      if ("AUTRE" !== this.currentIntervention.typeIntervention) {
        this.currentIntervention.otherTypeIntervention = null;
      }
      if ("AUTRE" !== this.currentIntervention.callOrigin) {
        this.currentIntervention.otherCallOrigin = null;
      }

      return {
        "intervention": this.currentIntervention,
        "vehiculeImpliqueList": this.vehiculesImpliques,
        "ddpList": this.ddp,
        "selectedAgents": this.selectedAgents
      }
    },
    
    createIntervention() {
      let interventionDataDTO = this.prepareInterventionData();
      interventionDataDTO.validated = false;
      interventionService.createIntervention(interventionDataDTO, this.files).then(data => {
        this.currentIntervention = data;
        let msg = this.$t("intervention.created");
        this.goToDetails(msg);
      })
    },
    
    createAndValidateIntervention() {
      let interventionDataDTO = this.prepareInterventionData();
      interventionDataDTO.validated = true;
      interventionService.createIntervention(interventionDataDTO, this.files).then(data => {
        this.currentIntervention = data;
        let msg = this.$t("intervention.created");
        this.goToDetails(msg);
      })
    },
    
    updateIntervention() {
      let interventionDataDTO = this.prepareInterventionData();
      interventionDataDTO.validated = false;
      interventionService.updateIntervention(interventionDataDTO, this.files).then(data => {
        this.currentIntervention = data;
        let msg = this.$t("intervention.updated");
        this.goToDetails(msg);
      })
    },
    
    updateAndValidateIntervention() {
      let interventionDataDTO = this.prepareInterventionData();
      interventionDataDTO.validated = true;
      interventionService.updateIntervention(interventionDataDTO, this.files).then(data => {
        this.currentIntervention = data;
        let msg = this.$t("intervention.updated");
        this.goToDetails(msg);
      })
    },

    addDdp() {
      let newDdp = {
        interventionId: this.interventionId,
        natureDegats: null,
        unite: null,
        quantite: null,
        canUpdateUnite: false,
        dateCreated: new Date(),
      };
      this.ddp.push(newDdp);
    },

    onNatureDegatsDdpChange(ddp) {
      switch (ddp.natureDegats) {
        case "AUTRES":
          ddp.canUpdateUnite = true;
          break;
        case "GLISSIERES":
          ddp.canUpdateUnite = false;
          ddp.unite = "m";
          break;
        case "PORTIQUE":
          ddp.canUpdateUnite = false;
          ddp.unite = "nb";
          break;
        case "SIGNALISATION_VERTICALE":
          ddp.canUpdateUnite = false;
          ddp.unite = "nb";
          break;
        case "ENROBE":
          ddp.canUpdateUnite = false;
          ddp.unite = "m2";
          break;
        default:
          ddp.canUpdateUnite = false;
          ddp.unite = null;
          break;
      }
    },

    addVehiculeImplique() {
      let newVehiculeImplique = {
        interventionId: this.interventionId,
        genre: null,
        marque: null,
        immatriculation: null,
        degatsMateriels: null,
        dommagesCorporels: null,
        responsableDdp: false,
        dateCreated: new Date(),
      };
      this.vehiculesImpliques.push(newVehiculeImplique);
    },

    openDeleteDialogVehiculeImplique(data) {
      this.currentVehiculeImplique = data;
      this.$refs.dialogDeleteVehiculeImplique.show();
    },

    openDeleteDialogDdp(data) {
      this.currentDdp = data;
      this.$refs.dialogDeleteDdp.show();
    },

    deleteVehiculeImplique() {
      if (this.currentVehiculeImplique == null) {
        return;
      }
      if (this.currentVehiculeImplique.id == null) {
        this.vehiculesImpliques.splice(this.vehiculesImpliques.indexOf(this.currentVehiculeImplique), 1);
        return;
      }
      vehiculeImpliqueService.deleteVehiculeImplique(this.currentVehiculeImplique.id).then(data => {
        this.vehiculesImpliques = this.vehiculesImpliques.filter(v => v.id !== this.currentVehiculeImplique.id);
      }).catch(error => {
        console.log(error);
      });
    },

    deleteDdp() {
      if (this.currentDdp == null) {
        return;
      }
      if (this.currentDdp.id == null) {
        this.ddp.splice(this.ddp.indexOf(this.currentDdp), 1);
        return;
      }
      ddpService.deleteDdp(this.currentDdp.id).then(data => {
        this.ddp = this.ddp.filter(d => d.id !== this.currentDdp.id);
      }).catch(error => {
        console.log(error);
      });
    },

    openUpdateDialog() {
      this.$refs.dialogUpdate.show();
    },
    
    openUpdateAndValidateDialog() {
      this.$refs.dialogUpdateAndValidate.show();
    },

    openCreateDialog() {
      this.$refs.dialogCreate.show();
    },
    
    openCreateAndValidateDialog() {
      this.$refs.dialogCreateAndValidate.show();
    },

    getUrl(file) {
      return this.generateUrl('/file/content/' + file.id);
    },

    deleteFile(file) {
      fileService.deleteInterventionFile(file).then(data => {
        this.filesToShow = this.filesToShow.filter(f => f.id !== file.id);
      }).catch(error => {
        console.log(error);
      });
    },

    goToDetails(msg) {
      this.$router.replace({path: `/intervention/${this.currentIntervention.id}/details`, query: {msg: msg}});
    },

    displayer(modelValue) {
      if (modelValue === undefined || modelValue === null || modelValue.toString() === '') return "";
      return modelValue;
    },

    inputDisplayForMoyensMaterielsConst(moyen) {
      return null != moyen ? this.$t("intervention.nb_item_prefix_label") + " " + this.displayForMoyensMaterielsConst(moyen) : ""
    },

    getAgentsForCei() {
      if (this.currentIntervention.ceiId != null) {
        ceiService.getCeiAgents(this.currentIntervention.ceiId).then(data => {
          this.ceiAgents = data;
        });
      } else {
        this.ceiAgents = [];
      }
    },

    isAxeInterventionBretelle(axeInterventionId) {
      if (this.isEmpty(axeInterventionId) || this.isEmpty(this.axeInterventionList)) return false;
      let axe = this.axeInterventionList.find(axe => axe.id === axeInterventionId);
      if (this.isEmpty(axe)) return false;
      return axe.technicalName === "BRETELLE";
    }
  },
  watch: {
    selectedCeiId(newCeiId, oldCeiId) {
      this.currentIntervention.ceiId = newCeiId;
      if (newCeiId) {
        ceiService.getCeiAgents(newCeiId).then(data => {
          this.ceiAgents = data;
        });
      } else {
        this.ceiAgents = [];
      }
    },
    precision1(oldValue, newValue) {
      if (!this.precision1) {
        this.currentIntervention.precisionNiveau1 = null;
      } else {
        if (!this.isPrecision1RelatedToTypeIntervention(this.currentIntervention.typeIntervention, this.currentIntervention.precisionNiveau1)) {
          this.currentIntervention.precisionNiveau1 = null;
        }
      }
    },
    precision2(oldValue, newValue) {
      if (!this.precision2) {
        this.currentIntervention.precisionNiveau2 = null;
      } else {
        if (!this.isPrecision2RelatedToPrecision1(this.currentIntervention.precisionNiveau1, this.currentIntervention.precisionNiveau2)) {
          this.currentIntervention.precisionNiveau2 = null;
        }
      }
    },
    canUpdate() {
      return this.currentIntervention.canUpdate;
    }
  }
}
</script>

<template>
  <Toast/>
  <template v-if="!this.loading">
    <AkFormView :ref="ref" :title="this.creation ? $t('intervention.create') : $t('intervention.update')">
      <template v-slot:form>
        <template v-if="!this.creation">
          <div class="form-row">
            <h5 class="mt-2 col-12">{{ $t('intervention.no_intervention_cei') }}</h5>
            <AkInputText v-model="this.currentIntervention.noInterventionCei" :disabled="true" class-name="col-md-1"/>
          </div>
        </template>
        <div class="form-row">
          <h5 class="mt-2 col-12">{{ $t('intervention.call_origin') }}</h5>
          <AkDateTime v-model="currentIntervention.callDate"
                      :showTime="false"
                      :disabled="!this.canUpdate && !this.creation"
                      :label="$t('intervention.call_date')"
                      :max-date="new Date()"
                      :class-name="'col-xs-6 col-md-2 ' + (Object.keys(this.errors).includes('callDate') ? 'datatable-validation-error' : '')"/>

          <AkInputMask v-model="currentIntervention.callTime"
                       :disabled="!this.canUpdate && !this.creation"
                       :label="$t('intervention.call_hour')"
                       :onchange="this.checkInterventionTimeValidity(currentIntervention)"
                       :class-name="'col-xs-6 col-md-2 ' + (Object.keys(this.errors).includes('callTime') ? 'datatable-validation-error' : '')"
                       mask="99:99"
                       placeholder="__:__"/>

          <AkDropdown :label="$t('intervention.call_origin')"
                      :filter="true"
                      v-model="currentIntervention.callOrigin"
                      :options=origineAppelConst
                      option-value="value"
                      option-label="label"
                      :disabled="!this.canUpdate && !this.creation"
                      :class-name="'col-md-4 ' + (Object.keys(this.errors).includes('callOrigin') ? 'datatable-validation-error' : '')"/>
          <AkInputText v-if="'AUTRE' ===currentIntervention.callOrigin"
                       :label="$t('intervention.other_call_origin')"
                       v-model="currentIntervention.otherCallOrigin"
                       :disabled="!this.canUpdate && !this.creation"
                       :class-name="'col-md-4 ' + (Object.keys(this.errors).includes('callOrigin') ? 'datatable-validation-error' : '')"/>
          <AkInputTextArea v-model="currentIntervention.observation"
                           :disabled="!this.canUpdate && !this.creation"
                           :label="$t('intervention.observation')"
                           rows="5"
                           :class-name="'col-md-12 ' + (Object.keys(this.errors).includes('observation') ? 'datatable-validation-error' : '')"/>
        </div>
        <div class="form-row">
          <h5 class="mt-2 col-12">{{ $t('intervention.lieu_intervention') }}</h5>
          <template v-if="this.creation">
            <AkDropdown
                :filter="true"
                v-if="userCeis.length>1"
                :label="$t('intervention.cei')"
                v-model="selectedCeiId"
                :options=userCeis
                option-value="id"
                option-label="label"
                :class-name="'col-md-4 ' + (Object.keys(this.errors).includes('ceiId') ? 'datatable-validation-error' : '')"/>
          </template>
          <template v-else>
            <AkInputText v-if="currentCei != null" v-model="currentCei.label" :disabled="true"
                         :label="$t('intervention.cei')" class-name="col-md-4"/>
          </template>

          <AkDropdownMultiple
              :label="$t('intervention.agents')"
              :filter="true"
              v-model="this.selectedAgents"
              :options=this.ceiAgents
              option-value="id"
              option-label="fullName"
              :disabled="!this.canUpdate && !this.creation"
              :class-name="'col-md-4'"/>

          <AkInputText
              v-model="this.currentIntervention.autreAgent"
              :disabled="!this.canUpdate && !this.creation"
              :label="$t('intervention.autre_agent')"
              placeholder="ex: Jean Dupont, Paul Martin"
              class-name="col-md-4"/>

          <AkDropdown :label="$t('intervention.axe')"
                      :filter="true"
                      v-model="currentIntervention.axeInterventionId"
                      :options=axeInterventionList
                      option-value="id"
                      option-label="libelle"
                      :disabled="!this.canUpdate && !this.creation"
                      :class-name="'col-md-4 ' + (Object.keys(this.errors).includes('axeInterventionId') ? 'datatable-validation-error' : '')"/>

          <template v-if="this.isAxeInterventionBretelle(currentIntervention.axeInterventionId)">
            <AkInputText :label="$t('intervention.noBretelle')"
                         v-model="currentIntervention.noBretelle"
                         :disabled="!this.canUpdate && !this.creation"
                         :class-name="'col-md-4 ' + (Object.keys(this.errors).includes('noBretelle') ? 'datatable-validation-error' : '')"/>
            <AkDropdown :label="$t('intervention.noAutoroute')"
                        :options="axeInterventionAutorouteList"
                        option-value="id"
                        option-label="libelle"
                        v-model="currentIntervention.autorouteId"
                        :disabled="!this.canUpdate && !this.creation"
                        :class-name="'col-md-4 ' + (Object.keys(this.errors).includes('autorouteId') ? 'datatable-validation-error' : '')"/>
          </template>

          <AkDropdownMultiple
              :label="$t('intervention.sens')"
              :filter="true"
              v-model="this.currentIntervention.sensListTmp"
              :options=sensConst
              option-value="value"
              option-label="label"
              :disabled="!this.canUpdate && !this.creation"
              :class-name="'col-md-4 ' + (Object.keys(this.errors).includes('sens') ? 'datatable-validation-error' : '')"/>

          <AkInputText v-model="this.currentIntervention.voie"
                       :disabled="!this.canUpdate && !this.creation"
                       :label="$t('intervention.voie')"
                       :class-name="'col-md-4 ' + (Object.keys(this.errors).includes('voie') ? 'datatable-validation-error' : '')"/>

          <AkInputText v-model="currentIntervention.localisation"
                       :disabled="!this.canUpdate && !this.creation"
                       :label="$t('intervention.pr')"
                       :regexp="usedPrValidationRegexp"
                       :tooltip="$t('intervention.pr_format_tooltip')"
                       :class-name="'col-md-4 ' + (Object.keys(this.errors).includes('localisation') ? 'datatable-validation-error' : '')"/>

          <AkInputText v-model="currentIntervention.commune"
                       :disabled="!this.canUpdate && !this.creation"
                       :label="$t('intervention.commune')"
                       :class-name="'col-md-4 ' + (Object.keys(this.errors).includes('commune') ? 'datatable-validation-error' : '')"/>

          <AkInputMask v-model="currentIntervention.heureArriveeSite"
                       :disabled="!this.canUpdate && !this.creation"
                       :label="$t('intervention.horaire_arrivee_site')"
                       mask="99:99"
                       :onchange="this.checkInterventionTimeValidity(currentIntervention)"
                       :class-name="'col-md-4 ' + (Object.keys(this.errors).includes('heureArriveeSite') ? 'datatable-validation-error' : '')"
                       placeholder="__:__"/>

          <AkInputMask v-model="currentIntervention.heureDepartSite"
                       :disabled="!this.canUpdate && !this.creation"
                       :label="$t('intervention.horaire_depart_site')"
                       mask="99:99"
                       :onchange="this.checkInterventionTimeValidity(currentIntervention)"
                       :class-name="'col-md-4 ' + (Object.keys(this.errors).includes('heureDepartSite') ? 'datatable-validation-error' : '')"
                       placeholder="__:__"/>
        </div>
        <div class="form-row">
          <h5 class="mt-2 col-12">{{ $t('intervention.intervention') }}</h5>
          <AkInputMask v-model="currentIntervention.heureDebut"
                       :disabled="!this.canUpdate && !this.creation"
                       :label="$t('intervention.heure_debut')"
                       :class-name="'col-md-4 ' + (Object.keys(this.errors).includes('heureDebut') ? 'datatable-validation-error' : '')"
                       mask="99:99"
                       :onchange="this.checkInterventionTimeValidity(currentIntervention)"
                       placeholder="__:__"/>

          <AkInputMask v-model="currentIntervention.heureFin"
                       :disabled="!this.canUpdate && !this.creation"
                       :label="$t('intervention.heure_fin')"
                       :class-name="'col-md-4 ' + (Object.keys(this.errors).includes('heureFin') ? 'datatable-validation-error' : '')"
                       mask="99:99"
                       :onchange="this.checkInterventionTimeValidity(currentIntervention)"
                       placeholder="__:__"/>

          <AkInputTextArea :label="$t('intervention.circonstances_connues')"
                           v-model="this.currentIntervention.circonstances"
                           :class-name="'col-md-4 ' + (Object.keys(this.errors).includes('circonstances') ? 'datatable-validation-error' : '')"
                           :disabled="!this.canUpdate && !this.creation"/>


          <h5 class="mt-2 col-12">{{ $t('intervention.vehicules_impliques') }}</h5>
          <AkInputNumber v-for="typeVehicule in vehiculeImpliqueConst" :key="typeVehicule.value"
                         v-model="this.vehiculesImpliquesDict[typeVehicule.value]"
                         :disabled="!this.canUpdate && !this.creation"
                         :label="displayForVehiculeImpliqueConst(typeVehicule.value)" class-name="col-md-2"/>

          <DataTable :paginator=false
                     :loading="loading"
                     :value="this.vehiculesImpliques"
                     class="table"
                     :sortOrder=-1
                     removableSort
                     responsiveLayout="scroll"
                     stripedRows
                     :title="$t('vehicules_impliques.title')">
            <template #empty>
              {{ $t("list_empty") }}
            </template>
            <Column field="genre" :header="$t('vehicules_impliques.genre')" :sortable="true">
              <template #body="slotProps">
                <AkDropdown label=""
                            :filter="true"
                            v-model="slotProps.data.genre"
                            :options=genreVehiculeConst
                            option-value="value"
                            option-label="label"
                            :disabled="!this.canUpdate && !this.creation"
                            class-name="col-md-12"/>
                <AkInputText v-model="slotProps.data.typeVehicule" v-if="slotProps.data.genre === 'AUTRE_VEHICULE'"
                             placeholder="Préciser autre véhicule"
                             :disabled="!this.canUpdate && !this.creation"
                             class-name="col-md-12"/>
              </template>
            </Column>
            <Column field="marque" :header="$t('vehicules_impliques.marque')" :sortable="true">
              <template #body="slotProps">
                <AkInputText label=""
                             v-model="slotProps.data.marque"
                             :disabled="!this.canUpdate && !this.creation"
                             class-name="col-md-12"/>
              </template>
            </Column>
            <Column field="immatriculation" :header="$t('vehicules_impliques.immatriculation')" :sortable="true">
              <template #body="slotProps">
                <AkInputText label=""
                             :disabled="!this.canUpdate && !this.creation"
                             v-model="slotProps.data.immatriculation"
                             class-name="col-md-12"/>
              </template>
            </Column>
            <Column field="degats_materiels" :header="$t('vehicules_impliques.degats_materiels')" :sortable="true">
              <template #body="slotProps">
                <AkInputText label=""
                             v-model="slotProps.data.degatsMateriels"
                             :disabled="!this.canUpdate && !this.creation"
                             class-name="col-md-12"/>
              </template>
            </Column>
            <Column field="dommages_corporels" :header="$t('vehicules_impliques.dommages_corporels')" :sortable="true">
              <template #body="slotProps">
                <AkInputText label=""
                             v-model="slotProps.data.dommagesCorporels"
                             :disabled="!this.canUpdate && !this.creation"
                             class-name="col-md-12"/>
              </template>
            </Column>
            <template v-if="currentIntervention.presenceTiersResponsable">
              <Column field="responsable_ddp" :header="$t('vehicules_impliques.responsable_ddp')" :sortable="true">
                <template #body="slotProps">
                  <AkCheckbox v-model="slotProps.data.responsableDdp"
                              :disabled="!this.canUpdate && !this.creation"
                              label="Responsable"
                              class-name="col-md-12"/>
                </template>
              </Column>
            </template>
            <Column bodyStyle="text-align: right; vertical-align: middle;"
                    headerStyle="width: 150px; text-align: right;">
              <template #body="slotProps" v-if="this.canUpdate && !this.creation">
                <button class="btn btn-xs btn-inverse-danger"
                        @click="openDeleteDialogVehiculeImplique(slotProps.data)">
                  <i class="fe fe-trash"/>
                </button>
              </template>
            </Column>
            <template #footer>
              <button @click='addVehiculeImplique()' class="btn btn-info" style="margin-left: 10px"
                      v-if="this.canUpdate || this.creation">
                {{ $t('vehicules_impliques.ajouter_vehicule') }}
              </button>
            </template>
          </DataTable>
        </div>


        <div class="form-row">
          <h5 class="mt-2 col-12">{{ $t('intervention.moyens_utilises') }}</h5>
          <AkInputNumber v-for="moyen in moyensHumainsConst" :key="moyen.value"
                         v-model="this.moyensHumainsUtilises[moyen.value]"
                         :disabled="!this.canUpdate && !this.creation"
                         :label="displayForMoyensHumainsConst(moyen.value)" class-name="col-md-2"/>
        </div>
        <br/>
        <br/>
        <div class="form-row">
          <h5 class="mt-2 col-12">{{ $t('intervention.moyens_materiels') }}</h5>
          <AkInputNumber v-for="moyen in moyensMaterielsConst" :key="moyen.value"
                         v-model="this.moyensMaterielsUtilises[moyen.value]"
                         :disabled="!this.canUpdate && !this.creation"
                         :label="inputDisplayForMoyensMaterielsConst(moyen.value)" class-name="col-md-2"/>
        </div>
        <div class="form-row">
          <h5 class="mt-2 col-12">{{ $t('intervention.environnement') }}</h5>
          <AkDropdown :label="$t('intervention.etat_chaussee')"
                      :filter="true"
                      v-model="currentIntervention.etatChaussee"
                      :options=etatChausseeConst
                      option-value="value"
                      option-label="label"
                      :disabled="!this.canUpdate && !this.creation"
                      class-name="col-md-4"/>

          <AkDropdown :label="$t('intervention.conditions_meteo')"
                      :filter="true"
                      v-model="currentIntervention.conditionMeteo"
                      :options=conditionMeteoConst
                      option-value="value"
                      option-label="label"
                      :disabled="!this.canUpdate && !this.creation"
                      class-name="col-md-4"/>
        </div>

        <div class="form-row">
          <AkDropdown :label="$t('intervention.type_intervention')"
                      :filter="true"
                      v-model="currentIntervention.typeIntervention"
                      :options=typeInterventionConst
                      option-value="value"
                      option-label="label"
                      :disabled="!this.canUpdate && !this.creation"
                      :class-name="'col-md-4 ' + (Object.keys(this.errors).includes('typeIntervention') ? 'datatable-validation-error' : '')"/>
          <template v-if="'AUTRE' === currentIntervention.typeIntervention ">
            <AkInputText
                :label="$t('intervention.other_type_intervention')"
                :disabled="!this.canUpdate && !this.creation"
                :class-name="'col-md-4 ' + (Object.keys(this.errors).includes('typeIntervention') ? 'datatable-validation-error' : '')"
                v-model="currentIntervention.otherTypeIntervention"
            />
          </template>
          <template v-if="this.precision1">
            <AkDropdown :label="$t('intervention.precision_niveau_1')"
                        :filter="true"
                        v-model="currentIntervention.precisionNiveau1"
                        :options=this.getPrecision1Const(currentIntervention.typeIntervention)
                        option-value="value"
                        option-label="label"
                        :disabled="!this.canUpdate && !this.creation"
                        :class-name="'col-md-4 ' + (Object.keys(this.errors).includes('precisionNiveau1') ? 'datatable-validation-error' : '')"
            />
          </template>
          <template
              v-if="this.precision2 && this.currentIntervention.precisionNiveau1 !== null && hasTypeInterventionAnyPrecision2(this.currentIntervention.typeIntervention)">
            <AkDropdown :label="$t('intervention.precision_niveau_2')"
                        :filter="true"
                        v-model="currentIntervention.precisionNiveau2"
                        :options=this.getPrecision2Const(currentIntervention.precisionNiveau1)
                        option-value="value"
                        option-label="label"
                        :disabled="!this.canUpdate && !this.creation"
                        :class-name="'col-md-4 ' + (Object.keys(this.errors).includes('precisionNiveau2') ? 'datatable-validation-error' : '')"/>
          </template>

          <AkDropdownMultiple :label="$t('intervenant.title')"
                              :filter="true"
                              :disabled="!this.canUpdate && !this.creation"
                              v-model="this.currentIntervention.intervenantsList"
                              :options=intervenantConst
                              option-value="value"
                              option-label="label"
                              :displayer="this.displayer"
                              class-name="col-md-4"/>

          <AkDropdownMultiple :label="$t('action.title')"
                              :filter="true"
                              :disabled="!this.canUpdate && !this.creation"
                              v-model="this.currentIntervention.actionsRealiseesList"
                              :options=actionConst
                              option-value="value"
                              option-label="label"
                              :displayer="this.displayer"
                              class-name="col-md-4"/>
        </div>
        <h5 class="mt-2">{{ $t('intervention.files') }}</h5>
        <template v-if="filesToShow.length > 0">
          <div class="form-row">
            <template v-for="file in filesToShow" :key="file.id">
              <div v-if="file.mimetype.includes('image/')" class="col-md-4" style="display: flex;">
                <a :href=getUrl(file) style="margin: 5px 0 5px 5px;" target="_blank">
                  <img :alt=file.name :src=getUrl(file) style="min-height: 100%; max-width: 100%;"/>
                </a>
                <i v-if="this.canUpdate || this.creation"
                   class="pi pi-trash p-1 trash-can"
                   @click="deleteFile(file)"/>
              </div>
            </template>
          </div>
          <div class="form-row">
            <template v-for="file in filesToShow" :key="file.id">
              <div v-if="!file.mimetype.includes('image/')" class="col-md-4" style="display: flex;">
                <a :href=getUrl(file) class="card file-card">
                  <img :alt="getThumbnailAlt(file.mimetype)" :src="getThumbnail(file.mimetype)"
                       style="max-width: 30px;"/>
                  <p style="margin-left: 5px; margin-right: 5px;">{{ file.name }}</p>
                </a>
                <i v-if="this.canUpdate || this.creation"
                   class="pi pi-trash p-1 trash-can"
                   @click="deleteFile(file)"/>
              </div>
            </template>
          </div>
        </template>
        <div class="form-row">
          <AkFileUpload v-model="this.files"
                        v-if="this.canUpdate || this.creation"
                        accept="application/pdf,image/jpg,image/jpeg,image/png"
                        label=""
                        class-name="col-md-4"/>
        </div>
        <div class="form-row">
          <h5 class="mt-2 col-12">{{ $t('ddp.title') }}</h5>
          <AkYesNoButton v-model="currentIntervention.ddp"
                         :disabled="!this.canUpdate && !this.creation"
                         :label="$t('ddp.title')" class-name="col-md-4"/>
          <template v-if="currentIntervention.ddp">
            <AkYesNoButton v-model="currentIntervention.presenceTiersResponsable"
                           :disabled="!this.canUpdate && !this.creation"
                           :label="$t('ddp.presence_tiers_responsable')" class-name="col-md-4"/>

            <DataTable :paginator=false
                       :loading="loading"
                       :value="this.ddp"
                       class="table"
                       :sortOrder=-1
                       removableSort
                       responsiveLayout="scroll"
                       stripedRows>
              <template #header>
                {{ $t('ddp.title') }}
              </template>
              <template #empty>
                {{ $t("list_empty") }}
              </template>
              <Column field="nature_degats" :header="$t('ddp.nature_degats')" :sortable="true">
                <template #body="slotProps">
                  <AkDropdown label=""
                              :filter="true"
                              v-model="slotProps.data.natureDegats"
                              :options=natureDegatsConst
                              option-value="value"
                              option-label="label"
                              :disabled="!this.canUpdate && !this.creation"
                              class-name=""
                              :(onBlur)="onNatureDegatsDdpChange(slotProps.data)"/>
                  <AkInputText v-if="'AUTRES' === slotProps.data.natureDegats"
                               label=""
                               :placeholder="$t('ddp.other_nature')"
                               v-model="slotProps.data.otherNatureDegats"
                               :disabled="!this.canUpdate && !this.creation"
                               class-name=""
                  />
                </template>
              </Column>
              <Column field="unite" :header="$t('ddp.unite')" :sortable="true">
                <template #body="slotProps">
                  <AkInputText
                      :disabled="!slotProps.data.canUpdateUnite || (!this.canUpdate && !this.creation)"
                      placeholder=""
                      v-model="slotProps.data.unite"
                      label=""
                      class-name=""/>
                </template>
              </Column>
              <Column field="quantite" :header="$t('ddp.quantite')" :sortable="true">
                <template #body="slotProps">
                  <AkInputNumber
                      :disabled="!this.canUpdate && !this.creation"
                      :min=0
                      placeholder=""
                      v-model="slotProps.data.quantite"
                      label=""
                      class-name=""/>
                </template>
              </Column>
              <Column bodyStyle="text-align: right; vertical-align: middle;"
                      headerStyle="width: 150px; text-align: right;">
                <template v-if="this.canUpdate || this.creation" #body="slotProps">
                  <button class="btn btn-xs btn-inverse-danger"
                          @click="openDeleteDialogDdp(slotProps.data)">
                    <i class="fe fe-trash"/>
                  </button>
                </template>
              </Column>
              <template #footer>
                <button @click='addDdp()' class="btn btn-info" style="margin-left: 10px"
                        v-if="this.canUpdate || this.creation">{{
                    $t('ddp.ajouter_ddp')
                  }}
                </button>
              </template>
            </DataTable>
          </template>
          <AkInputTextArea v-model="currentIntervention.ddpObservations" :label="$t('ddp.observations')"
                           class-name="col-lg-12"/>
        </div>
        <div style="margin-top: 10px; text-align: right;">
          <button class="btn btn-success"
                  style="margin-left: 10px" @click=validateIntervention()>
            {{ $t('intervention.validate_intervention') }}
          </button>
          <button class="btn btn-primary"
                  style="margin-left: 10px" @click=saveDraftIntervention()>
            {{ $t('save_draft') }}
          </button>
        </div>
      </template>
    </AkFormView>
    
    <AkDialog ref="dialogDeleteVehiculeImplique"
              :auto-hide-on-validate="true"
              :cancel-label="$t('no')"
              :title="$t('vehicules_impliques.delete_dialog')"
              :validate-label="$t('yes')"
              width="450px"
              @validate="this.deleteVehiculeImplique()">
      <div class="confirmation-content">
        <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem"/>
        <span>{{ $t('vehicules_impliques.confirm_delete') }}</span>
      </div>
    </AkDialog>
    
    <AkDialog ref="dialogDeleteDdp"
              :auto-hide-on-validate="true"
              :cancel-label="$t('no')"
              :title="$t('ddp.delete_dialog')"
              :validate-label="$t('yes')"
              width="450px"
              @validate="this.deleteDdp()">
      <div class="confirmation-content">
        <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem"/>
        <span>{{ $t('ddp.confirm_delete') }}</span>
      </div>
    </AkDialog>
    
    <AkDialog ref="dialogCreate"
              :auto-hide-on-validate="true"
              :cancel-label="$t('no')"
              :title="$t('intervention.create')"
              :validate-label="$t('yes')"
              width="450px"
              @validate="this.createIntervention()">
      <div class="confirmation-content">
        <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem"/>
        <span>{{ $t('intervention.confirm_create') }}</span>
      </div>
    </AkDialog>
    
    <AkDialog ref="dialogCreateAndValidate"
              :auto-hide-on-validate="true"
              :cancel-label="$t('no')"
              :title="$t('intervention.create')"
              :validate-label="$t('yes')"
              width="450px"
              @validate="this.createAndValidateIntervention()">
      <div class="confirmation-content">
        <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem"/>
        <span>{{ $t('intervention.confirm_create_validate') }}</span>
      </div>
    </AkDialog>
    
    <AkDialog ref="dialogUpdate"
              :auto-hide-on-validate="true"
              :cancel-label="$t('no')"
              :title="$t('intervention.edit_dialog')"
              :validate-label="$t('yes')"
              width="450px"
              @validate="this.updateIntervention()">
      <div class="confirmation-content">
        <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem"/>
        <span>{{ $t('intervention.confirm_update') }}</span>
      </div>
    </AkDialog>
    
    <AkDialog ref="dialogUpdateAndValidate"
              :auto-hide-on-validate="true"
              :cancel-label="$t('no')"
              :title="$t('intervention.edit_dialog')"
              :validate-label="$t('yes')"
              width="450px"
              @validate="this.updateAndValidateIntervention()">
      <div class="confirmation-content">
        <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem"/>
        <span>{{ $t('intervention.confirm_update_validate') }}</span>
      </div>
    </AkDialog>
    
  </template>
</template>
<style>
.trash-can {
  cursor: pointer;
  border-radius: 0 .25rem .25rem 0;
  margin: 5px 5px 5px 0;
  border: 1px solid #e7eaf3;
  border-left-width: 0;
}

.trash-can:hover {
  color: #40b4e5;
  background-color: #40b4e520;
}

.file-card {
  align-items: center;
  flex-direction: row;
  padding: 5px 5px;
  margin: 5px 0;
  border-radius: .25rem;
  width: -webkit-fill-available;
}
</style>